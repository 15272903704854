import React from 'react';

import styles from './Hero.module.scss';

const Hero = ({ bgPath, isMobile }) => {
    const _bg = `${bgPath}/hero-${isMobile ? 'mobile' : 'desktop'}.jpg`;
    return (
        <div className={styles['container']} style={{ backgroundImage: `url(/img/pages/${_bg})` }}></div>
    )
}

export default Hero;

import { connect } from 'react-redux';
import App from './App';

import { setMobile } from '../features/site/redux/SiteOperations';

const mapDispatchToProps = {
    setMobile: isMobile => setMobile(isMobile)
};

export default connect(null, mapDispatchToProps)(App);

import React from 'react';

import styles from './Icon.module.scss';

const Icon = ({ icon, customSize }) => {
    if(typeof icon === 'object' && (!icon['$$typeof'] || icon['$$typeof'].toString() !== 'Symbol(react.element)'))
        return null;

    const containerClass = `${styles['container']} ${customSize ? styles['custom-size'] : ''}`;
    return (
        <div className={containerClass} data-test="component-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                viewBox={icon.props.viewBox}
            >
                {icon}
            </svg>
        </div>
    );
}

export default Icon;

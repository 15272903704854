import React, { Fragment } from 'react';

import Icon from 'components/Icon';
import { IconFacebook, IconInstagram } from 'components/Icon/IconSet';

import Constants from 'common/Constants';

import styles from './Footer.module.scss';

const social = [
    { icon: IconFacebook, link: Constants.Facebook },
    { icon: IconInstagram, link: Constants.Instagram }
];

const links = [
    { label: 'UFMG', link: 'https://ufmg.br' },
    { label: 'Escola de Engenharia', link: 'https://www.eng.ufmg.br' },
    { label: 'Dpto. de Engenharia Elétrica (DELE)', link: 'http://dee.ufmg.br' },
    { label: 'Dpto. de Engenharia Eletrônica (DELT)', link: 'http://www.delt.ufmg.br' },
    { label: 'Instituto de Ciências Exatas (ICEx)', link: 'http://www.icex.ufmg.br' },
    { label: 'minhaUFMG', link: 'https://minha.ufmg.br' },
    { label: 'SigaUFMG', link: 'https://sistemas.ufmg.br/aluno-grad/' },
    { label: 'Sistemas de Bibliotecas', link: 'https://catalogobiblioteca.ufmg.br/pergamum/biblioteca/index.php' }
];

const Footer = () => {
    return (
        <div className={styles['container']}>

            <div className={styles['content']}>
                <div className={'holder'}>
                    <div className={styles['content-holder']}>

                        <div className={styles['social']}>
                            {social.map(({ icon, link }, i) => {
                                return (
                                    <div className={styles['social-item']} key={i}>
                                        <a href={link} target={'_blank'} rel={'noopener noreferrer'}>
                                            <div className={styles['social-item-icon']}>
                                                <Icon icon={icon} customSize />
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={styles['info']}>

                            <div className={styles['main']}>
                                <div className={styles['logo']}>
                                    Graduação em<br /><span>Engenharia de Sistemas</span>
                                </div>
                                <div className={styles['address']}>
                                    <address>
                                        Av. Antônio Carlos, 6627 - Pampulha<br />
                                        Belo Horizonte/MG - Brasil<br />
                                        CEP 31270-901
                                    </address>
                                </div>
                                <div className={styles['split']}></div>
                                <div className={styles['ufmg']}></div>
                            </div>

                            <div className={styles['contacts']}>
                                <div className={styles['block']}>
                                    <div className={styles['block-title']}>Colegiado</div>
                                    <div className={styles['block-content']}>
                                        <a href={'tel:(31) 3409-3556'}>(31) 3409-3556</a><br />
                                        <a href={'mailto:eng-colgradsis@ufmg.br'}>eng-colgradsis@ufmg.br</a><br />
                                        Escola de Engenharia - Bloco 3<br />
                                        Sala 3035
                                    </div>
                                </div>

                                <div className={styles['block']}>
                                    <div className={styles['block-title']}>Grêmio</div>
                                    <div className={styles['block-content']}>
                                        <a href={'mailto:contato@geesufmg.com'}>contato@geesufmg.com</a><br />
                                        Escola de Engenharia - Bloco 3<br />
                                        Hall dos Grêmios - Sala T028
                                    </div>
                                </div>
                            </div>

                            <div className={styles['links']}>
                                <div className={styles['block']}>
                                    <div className={styles['block-title']}>Links úteis</div>
                                    <div className={styles['block-content']}>
                                        {links.map(({ label, link }, i) => (
                                            <Fragment key={i}>
                                                <a href={link} target={'_blank'} rel={'noopener noreferrer'}>{label}</a><br />
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className={styles['bottom']}>
                <div className={'holder'}>
                    <div className={styles['bottom-holder']}>

                        <div className={styles['vbases']}>
                            <a href={'https://vbases.com'} target={'_blank'} rel={'noopener noreferrer'}>
                                <div className={styles['vbases-logo']}></div>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;

import React, { Component } from 'react';

import styles from './Slip.module.scss';

class Slip extends Component {
    state = {
        isClosing: false
    };

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;

        if(!isOpen && prevProps.isOpen)
            this.setState({ isClosing: true });
    }

    render() {
        const { isOpen, closeCallback, children } = this.props;
        const { isClosing } = this.state;

        isClosing && setTimeout(() => this.setState({ isClosing: false }), 250);

        const backdropClass = `${styles['backdrop']} ${isOpen ? styles['open'] : ''} ${isClosing ? styles['is-closing'] : ''}`;
        return (
            <div className={backdropClass} onClick={() => closeCallback()}>
                <div className={styles['container']} onClick={ev => ev.stopPropagation()}>
                    <div className={styles['content']}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export default Slip;

import React from 'react';
import { Helmet } from 'react-helmet';

import Constants from 'common/Constants';

import Hero from '../Hero';
import Breadcrumb from '../Breadcrumb';
import Sidebar from '../Sidebar';

import styles from './Page.module.scss';

export const Link = ({ link, children }) => <a href={link} target={'_blank'} rel={'noopener noreferrer'}>{children}</a>;

const Page = ({ heroBgPath, breadcrumb, title, children, headValues = null }) => {
    let headTitle = Constants.PageTitleDefault;
    if(headValues?.title)
        headTitle = `${headValues.title} - ${headTitle}`;
    
    return (
        <>
            <Helmet>
                <title>{headTitle}</title>
                {!!headValues?.canonical && <link rel='canonical' href={headValues.canonical} />}
            </Helmet>

            <div className={styles['container']}>

                {!!heroBgPath && <Hero bgPath={heroBgPath} />}

                <div className={styles['content']}>
                    <div className={'holder'}>
                        <div className={styles['content-holder']}>
                            <section className={styles['main']}>

                                {!!breadcrumb && <div className={styles['breadcrumb']}><Breadcrumb breadcrumb={breadcrumb} /></div>}
                                {!!title && <div className={styles['title']}><h1>{title}</h1></div>}

                                <div className={`${styles['children']} text`}>{children}</div>

                            </section>
                            <Sidebar />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Page;

import React from 'react';
import Ink from 'react-ink';

import Icon from '../Icon';
import LoadingDots from '../LoadingDots';

import styles from './Button.module.scss';

const Button = ({ value, icon, isLoading, onClick, theme = '', valueIcon = null, disabled = false }) => {
    const handleClick = ev => {
        ev.stopPropagation();
        !disabled && onClick && onClick();
    }

    const containerClass = `${styles['container']} ${isLoading ? styles['is-loading'] : ''} ${theme ? styles[`theme-${theme}`] : ''} ${disabled ? styles['disabled'] : ''}`;
    return (
        <div className={containerClass} onClick={handleClick}>
            {icon &&
                <div className={styles['icon']}>
                    <Icon icon={icon} customSize />
                </div>
            }
            {value &&
                <div className={styles['value']}>
                    {value}
                    {valueIcon &&
                        <div className={styles['value-icon']}>
                            <Icon icon={valueIcon} customSize />
                        </div>
                    }
                </div>
            }
            <Ink style={{ color: 'var(--color-base)' }} />
            {isLoading &&
                <div className={styles['loading']}>
                    <LoadingDots />
                </div>
            }
        </div>
    )
}

export default Button;

import React, { useRef, useEffect } from 'react';

import styles from './LoadingDots.module.scss';

const LoadingDots = ({ size }) => {
    const refOne = useRef();
    const refTwo = useRef();
    const refThree = useRef();
    
    useEffect(() => {
        if(size) {
            refOne.current.style.width = `${size}px`;
            refOne.current.style.height = `${size}px`;
            refTwo.current.style.width = `${size}px`;
            refTwo.current.style.height = `${size}px`;
            refThree.current.style.width = `${size}px`;
            refThree.current.style.height = `${size}px`;
        }
    });

    return (
        <div className={styles['container']}>
            <div className={styles['one']} ref={refOne}></div>
            <div className={styles['two']} ref={refTwo}></div>
            <div className={styles['three']} ref={refThree}></div>
        </div>
    )
}

export default LoadingDots;

import React from 'react';
import Ink from 'react-ink';

import Menu from '../Menu';
import MenuDrawer from '../MenuDrawer';

import Pages from 'common/Pages';
import Util from 'common/Util';

import styles from './Header.module.scss';

const Header = () => {
    return (
        <div className={styles['container']}>
            <div className={styles['height-fix']}></div>
            <div className={styles['content']}>
                <div className={'holder'}>

                    <div className={styles['content-holder']}>

                        <div className={styles['logo']} onClick={() => Util.redirect(Pages.Home)}>
                            <div className={styles['logo-image']}></div>
                            <div className={styles['logo-split']}></div>
                            <div className={styles['logo-text']}>
                                Graduação em<br />
                                <span>Engenharia de Sistemas</span>
                            </div>
                            <Ink />
                        </div>

                        <div className={styles['menu-desktop']}>
                            <Menu />
                        </div>

                        <div className={styles['menu-mobile']}>
                            <MenuDrawer />
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Header;

const Actions = {
    SET_AUTH: 'SET_AUTH',
    SET_IS_AUTH: 'SET_IS_AUTH',
    SET_RANK: 'SET_RANK',
    SET_QUESTIONS_LIST: 'QUESTIONS_LIST',
    APPEND_QUESTIONS_LIST: 'APPEND_QUESTIONS_LIST',
    SET_MODAL_LOGOUT_OPEN: 'MODAL_LOGOUT_OPEN'
};

export default Actions;

import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import AppLoading from 'components/AppLoading';

import Pages from 'common/Pages';

import Header from './components/Header';
import Footer from './components/Footer';

import styles from './Site.module.scss';

import Home from './pages/Home';
const EngenhariaSistemas = React.lazy(() => import('./pages/sobre/EngenhariaSistemas'));
const EngenhariaSistemasUfmg = React.lazy(() => import('./pages/sobre/EngenhariaSistemasUfmg'));
const Surgimento = React.lazy(() => import('./pages/sobre/Surgimento'));
const BasesLegais = React.lazy(() => import('./pages/sobre/BasesLegais'));
const CursosInternacionais = React.lazy(() => import('./pages/sobre/CursosInternacionais'));
const NucleosConceituais = React.lazy(() => import('./pages/curso/NucleosConceituais'));
const FormasIngresso = React.lazy(() => import('./pages/curso/FormasIngresso'));
const GradeCurricular = React.lazy(() => import('./pages/curso/GradeCurricular'));
const FaleConosco = React.lazy(() => import('./pages/contato/FaleConosco'));
const PerguntasRespostas = React.lazy(() => import('./pages/contato/PerguntasRespostas'));
const Page404 = React.lazy(() => import('./pages/Page404'));

const Site = ({ history }) => {
    return (
        <div className={styles['container']}>

            <Header />

            <div className={styles['content']}>
                <Router history={history}>
                    <Suspense fallback={<AppLoading />}>
                        <Switch>
                            <Route path={Pages.Home} exact render={() => <Home />} />

                            <Route path={Pages.Sobre.EngenhariaDeSistemas} render={() => <EngenhariaSistemas />} />
                            <Route path={Pages.Sobre.CursoNaUfmg} render={() => <EngenhariaSistemasUfmg />} />
                            <Route path={Pages.Sobre.Surgimento} render={() => <Surgimento />} />
                            <Route path={Pages.Sobre.BasesLegais} render={() => <BasesLegais />} />
                            <Route path={Pages.Sobre.CursosInternacionais} render={() => <CursosInternacionais />} />

                            <Route path={Pages.Curso.NucleosConceituais} render={() => <NucleosConceituais />} />
                            <Route path={Pages.Curso.FormasIngresso} render={() => <FormasIngresso />} />
                            <Route path={Pages.Curso.GradeCurricular} render={() => <GradeCurricular />} />

                            <Route path={Pages.Contato.FaleConosco} render={() => <FaleConosco />} />
                            <Route path={Pages.Contato.PerguntasRespostas} render={() => <PerguntasRespostas />} />

                            <Route render={() => <Page404 />} />
                        </Switch>
                    </Suspense>
                </Router>
            </div>

            <Footer />

        </div>
    )
}

export default Site;

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import SiteReducer from '../features/site/redux/SiteReducer';
import AdminReducer from '../features/admin/redux/AdminReducer';

const sitePersistConfig = { key: 'site', storage };
const adminPersistConfig = { key: 'admin', storage };

const appReducers = combineReducers({
    site: persistReducer(sitePersistConfig, SiteReducer),
    admin: persistReducer(adminPersistConfig, AdminReducer)
});

const rootReducer = (state, action) => {
    return appReducers(state, action);
};

export default () => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    const persistor = persistStore(store);
    return { store, persistor };
};

import Actions from './SiteActionsConstants';

const SiteActions = {
    setMobile: isMobile => ({
        type: Actions.SET_MOBILE,
        payload: isMobile
    }),

    setQuestions: questions => ({
        type: Actions.SET_QUESTIONS,
        payload: questions
    })
}

export default SiteActions;

const bases = {
    site: '/',
    sobre: '/sobre',
    curso: '/o-curso',
    contato: '/contato',
    admin: '/admin',
    adminLogin: '/admin/login',
    adminContent: '/admin/contents',
    adminSettings: '/admin/settings'
};

const Pages = {
    Home: bases.site,
    Sobre: {
        Base: bases.sobre,
        EngenhariaDeSistemas: `${bases.sobre}/engenharia-de-sistemas`,
        CursoNaUfmg: `${bases.sobre}/o-curso-na-ufmg`,
        Surgimento: `${bases.sobre}/surgimento`,
        BasesLegais: `${bases.sobre}/bases-legais`,
        CursosInternacionais: `${bases.sobre}/cursos-internacionais`,
    },
    Curso: {
        Base: bases.curso,
        NucleosConceituais: `${bases.curso}/nucleos-conceituais`,
        FormasIngresso: `${bases.curso}/formas-de-ingresso`,
        GradeCurricular: `${bases.curso}/grade-curricular`,
    },
    Contato: {
        Base: bases.contato,
        FaleConosco: `${bases.contato}/fale-conosco`,
        PerguntasRespostas: `${bases.contato}/perguntas-e-respostas`
    },

    Admin: {
        Base: bases.admin,
        Login: {
            Base: bases.adminLogin,
            PasswordRecovery: `${bases.adminLogin}/password-recovery`,
            PasswordReset: `${bases.adminLogin}/password-reset`
        },
        Contents: {
            Base: bases.adminContent,
            Questions: `${bases.adminContent}/questions`,
            Faq: `${bases.adminContent}/faq`
        },
        Settings: {
            Base: bases.adminSettings,
            UserData: `${bases.adminSettings}/user-data`,
            ChangePassword: `${bases.adminSettings}/change-password`,
            Users: `${bases.adminSettings}/users`
        }
    }
};

export default Pages;

import React from 'react';

import LoadingDots from '../LoadingDots';

import styles from './AppLoading.module.scss';

const AppLoading = () => {
    return (
        <div className={styles['container']}>
            <div className={styles['loading']}>
                <LoadingDots size={16} />
            </div>
        </div>
    )
}

export default AppLoading;

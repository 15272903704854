export const EnvironmentTypes = {
    Local: 1,
    Tst: 2,
    Prd: 3
};

const Environment = {
    getCurrent: () => {
        const hostname = window?.location?.hostname;
        const { Local, Tst, Prd } = EnvironmentTypes;

        const environments = {
            'localhost': Local,
            'preview': Tst,
            'geesufmg': Prd
        };

        for(const [url, env] of Object.entries(environments))
            if(hostname.includes(url))
                return env;
        
        return Prd;
    },

    apiUrls: {
        Local: 'http://localhost:30002',
        Tst: 'https://api.vbases.io/gees',
        Prd: 'https://api.server.vbases.com/gees'
    },

    ApiUrl: () => {
        const current = Environment.getCurrent();
        const { Local, Tst, Prd } = EnvironmentTypes;
        const { apiUrls } = Environment;

        switch(current) {
            case Local:
                return apiUrls.Local;
            
            case Tst:
                return apiUrls.Tst;
            
            case Prd:
                return apiUrls.Prd;
            
            default:
                return apiUrls.Local;
        }
    },

    basePathUrls: {
        Local: 'http://localhost:3000',
        Tst: 'https://tst.geesufmg.com',
        Prd: 'https://geesufmg.com'
    },

    BasePath: () => {
        const { Local, Tst, Prd } = EnvironmentTypes;
        const { basePathUrls } = Environment;

        switch(Environment.getCurrent()) {
            case Local:
                return basePathUrls.Local;
            
            case Tst:
                return basePathUrls.Tst;
            
            case Prd:
                return basePathUrls.Prd;
            
            default:
                return basePathUrls.Prd;
        }
    }
}

export default Environment;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import { IconPointer } from 'components/Icon/IconSet';

import Constants from 'common/Constants';
import Util from 'common/Util';
import Pages from 'common/Pages';

import styles from './Menu.module.scss';

const Menu = () => {
    const [justClicked, setJustClicked] = useState(false);

    const handleClick = hasList => {
        Util.scrollTo(0, false);

        if(hasList) {
            setJustClicked(true);
            setTimeout(() => setJustClicked(false), 200);
        }
    }

    const [currentPage, currentSubpage] = Util.getPathnameParams();

    return (
        <nav className={`${styles['container']} ${justClicked ? styles['just-clicked'] : ''}`}>
            <ul>
                {Constants.Menu.map(({ Label, Target, List }, i) => {
                    const _current = (!currentPage && Target === Pages.Home) || currentPage === Target.slice(1);
                    return (
                        <li key={i}>
                            <div className={`${styles['item']} ${_current ? styles['current'] : ''}`}>
                                <div className={styles['item-label']}>
                                    <Link to={List ? List[0].Target : Target} onClick={() => handleClick(!!List)}>
                                        <div className={styles['item-label-holder']}>
                                            <div className={styles['item-label-text']}>{Label}</div>
                                            {List &&
                                                <div className={styles['item-label-icon']}>
                                                    <Icon icon={IconPointer} customSize />
                                                </div>
                                            }
                                        </div>
                                    </Link>
                                </div>

                                {List &&
                                    <ul>
                                        <div className={styles['item-list']}>
                                            {List.map(({ Label, Target }, j) => {
                                                const _currentList = currentSubpage === Target.slice(1).split('/')[1];
                                                return (
                                                    <li className={`${styles['item-list-item']} ${_currentList ? styles['current'] : ''}`} key={`${i}${j}`}>
                                                        <Link to={Target} onClick={() => handleClick(true)}>
                                                            <div className={styles['item-list-item-label']}>{Label}</div>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </div>
                                    </ul>
                                }
                            </div>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Menu;

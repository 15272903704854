import Actions from './AdminActionsConstants';

const initialState = {
    auth: null,
    isAuth: false,
    rank: null,
    questionsList: { list: null, page: null },
    modalLogoutOpen: false
};

const AdminReducer = (state = initialState, { type, payload }) => {
    switch(type) {
        case Actions.SET_AUTH:
            if(payload === null)
                return { ...initialState };
            return { ...state, auth: payload };
        
        case Actions.SET_IS_AUTH:
            return { ...state, isAuth: payload };
        
        case Actions.SET_RANK:
            return { ...state, rank: payload };
        
        case Actions.SET_QUESTIONS_LIST:
            return { ...state, questionsList: { list: payload, page: 1 } };
        
        case Actions.APPEND_QUESTIONS_LIST:
            if(payload === null)
                return { ...state, questionsList: initialState.questionsList };
            return { ...state, questionsList: { list: [ ...state.questionsList.list, ...payload.appendix ], page: payload.page } };
        
        case Actions.SET_MODAL_LOGOUT_OPEN:
            return { ...state, modalLogoutOpen: payload };
        
        default:
            return state;
    }
}

export default AdminReducer;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './infrastructure/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './infrastructure/history';

import Analytics from './common/constants/Analytics';

import Util from './common/Util';

const { store } = configureStore();

Util.appendScriptTagToHead(Analytics.Url);
Util.appendScriptContentToHead(Analytics.Script);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();

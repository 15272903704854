import React from 'react';

import Icon from 'components/Icon';
import { IconHome } from 'components/Icon/IconSet';

import Util from 'common/Util';
import Pages from 'common/Pages';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ breadcrumb }) => {
    return (
        <div className={styles['container']}>
            <ol className={styles['list']}>
                <li className={styles['item']} onClick={() => Util.redirect(Pages.Home.Url)}>
                    <div className={styles['item-home']}>
                        <Icon icon={IconHome} customSize />
                    </div>
                </li>

                {breadcrumb.map((item, i) => {
                    return (
                        <li className={styles['item']} key={i}>
                            {item}
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}

export default Breadcrumb;

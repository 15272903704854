import React from 'react';
import { Link } from 'react-router-dom';

import Pages from 'common/Pages';
import Util from 'common/Util';

import styles from './Sidebar.module.scss';

const links = [
    { label: 'Grade curricular', target: Pages.Curso.GradeCurricular },
    { label: 'Núcleos conceituais', target: Pages.Curso.NucleosConceituais },
    { label: 'Formas de ingresso', target: Pages.Curso.FormasIngresso },
    { label: 'Sobre o curso na UFMG', target: Pages.Sobre.CursoNaUfmg },
    { label: 'Cursos internacioanis', target: Pages.Sobre.CursosInternacionais }
];

const handleClick = () => {
    Util.scrollTo(0, false);
}

const Sidebar = () => {
    return (
        <aside className={styles['container']}>
            <div className={styles['item']}>
                <div className={styles['title']}>Links úteis</div>
                <div className={styles['list']}>
                    <ul>
                        {links.map(({ label, target }, i) => {
                            return (
                                <li key={i}>
                                    <Link to={target} onClick={handleClick}>{label}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Ink from 'react-ink';

import Icon from 'components/Icon';
import { IconMenu, IconClose, IconFacebook, IconInstagram, IconPointer } from 'components/Icon/IconSet';
import Slip from 'components/Slip';

import Constants from 'common/Constants';
import Util from 'common/Util';

import styles from './MenuDrawer.module.scss';
import SlideDown from 'react-slidedown';

const social = [
    { icon: IconFacebook, link: Constants.Facebook },
    { icon: IconInstagram, link: Constants.Instagram }
];

const MenuDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [itemOpen, setItemOpen] = useState(null);

    useEffect(() => {
        if(isOpen)
            return;
        
        setItemOpen(null);
    }, [isOpen]);

    const handleClick = (ev, list, index) => {
        if(!list) {
            Util.scrollTo(0, false);
            setIsOpen(false);
            return;
        }
        
        ev.preventDefault();
        setItemOpen(itemOpen === index ? null : index);
    }

    return (
        <>
            <div className={styles['button']} onClick={() => setIsOpen(true)}>
                <Icon icon={IconMenu} customSize />
            </div>

            <Slip isOpen={isOpen} closeCallback={() => setIsOpen(false)}>
                <div className={styles['drawer']}>

                    <div className={styles['drawer-top']}>
                        <div className={styles['drawer-social']}>
                            {social.map(({ icon, link }, i) => {
                                return (
                                    <div className={styles['drawer-social-item']} key={i} onClick={() => Util.redirect(link)}>
                                        <Icon icon={icon} customSize />
                                    </div>
                                )
                            })}
                        </div>

                        <div className={styles['drawer-close']} onClick={() => setIsOpen(false)}>
                            <Icon icon={IconClose} customSize />
                        </div>
                    </div>

                    <div className={styles['drawer-content']}>
                        <nav className={styles['menu']}>
                            <ul>
                                {Constants.Menu.map(({ Label, Target, List }, i) => {
                                    return (
                                        <li key={i} className={styles['item']}>
                                            <div className={styles['item-label']}>
                                                <Link to={Target} onClick={ev => handleClick(ev, List, i)}>
                                                    <div className={styles['item-label-holder']}>
                                                        <div className={styles['item-label-text']}>{Label}</div>
                                                        {List &&
                                                            <div className={styles['item-label-pointer']}>
                                                                <Icon icon={IconPointer} customSize />
                                                            </div>
                                                        }
                                                        <Ink />
                                                    </div>
                                                </Link>
                                            </div>

                                            {List &&
                                                <SlideDown closed={itemOpen !== i} className={styles['item-list-holder']}>
                                                    <ul className={styles['item-list']}>
                                                        {List.map(({ Label, Target }, j) => {
                                                            return (
                                                                <li key={`${i}${j}`} className={styles['item-list-item']}>
                                                                    <Link to={Target} onClick={handleClick}>
                                                                        <div className={styles['item-list-item-label']}>{Label}</div>
                                                                        <Ink />
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </SlideDown>
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>

                </div>
            </Slip>
        </>
    )
}

export default MenuDrawer;

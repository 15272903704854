import Environment, { EnvironmentTypes } from '../../infrastructure/Environment';

const ids = {};
ids[EnvironmentTypes.Local] = 'UA-175452289-3';
ids[EnvironmentTypes.Tst] = 'UA-175452289-3';
ids[EnvironmentTypes.Prd] = 'UA-175452289-4';

const id = ids[Environment.getCurrent()];
const Url = `https://www.googletagmanager.com/gtag/js?id=${id}`;
const Script = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}');

    (function() {
        window.addEventListener('analytics.event', function(e) {
            try {
                const tracker = ga.create('${id}', 'auto');

                tracker.send({
                    hitType: 'event',
                    eventCategory: e.detail.category,
                    eventAction: e.detail.action,
                    eventLabel: e.detail.label
                });
            }
            catch(e) {
                console.error('Error on Analytics Event:');
                console.error(e);
            }
        });
    })();
`;

export default { Url, Script };

import React from 'react';
import Ink from 'react-ink';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Icon from 'components/Icon';
import { IconChevronRight } from 'components/Icon/IconSet';
import Button from 'components/Button';

import Pages from 'common/Pages';
import Util from 'common/Util';
import Environment from 'infrastructure/Environment';

import Page from '../../components/Page';

import styles from './Home.module.scss';

const slides = [
    {
        id: 'tradicao',
        text: 'Escola com tradição centenária para formar engenheiros',
        subtext: 'Desde 1911, a Escola de Engenharia se destaca entre as melhores do mundo.'
    },
    {
        id: 'problemas-complexos',
        text: 'Solucionar problemas complexos requer profissionais completos',
    }
];

const numbers = [
    { prefix: 'Mais de', number: '100', text: 'Engenheiros formados' },
    { prefix: 'Nota', number: '5', text: 'Avaliação do MEC', suffix: 'Nota máxima' },
    { prefix: 'Curso', number: '5★', text: 'Guia do Estudante', suffix: '2018' },
    { prefix: 'Nota', number: '4', text: 'Enade', suffix: 'Engenharia Geral' }
];

const nucleos = [
    {
        id: 'eletronica',
        title: 'Eletricidade e eletrônica',
        text: 'O curso de Engenharia de Sistemas, no caso da UFMG, surge como uma derivação da grande...'
    },
    {
        id: 'simulacao',
        title: 'Simulação e projeto multifísica',
        text: 'As áreas tradicionais da engenharia já vêm, há décadas, trabalhando na construção de sistemas...'
    },
    {
        id: 'modelagem',
        title: 'Modelagem e otimização de sistemas',
        text: 'Essas técnicas, que constituem um extenso repertório de ferramentas “livres-de-contexto”...'
    }
];

const internacionais = [
    {
        id: 'polytechnique',
        title: 'Ecole Polytechnique',
        location: 'Paris, França',
        text: 'Esse curso foi criado em 2003, e oferece três modalidades de especialização...'
    },
    {
        id: 'sheffield',
        title: 'University of Sheffield',
        location: 'Sheffield, Inglaterra',
        text: 'Essa universidade, além de oferecer cursos de engenharia de recorte tradicional...'
    },
    {
        id: 'mit',
        title: 'MIT',
        location: 'Cambridge, EUA',
        text: 'No MIT, a Engenharia de Sistemas (que é oferecida no nível de mestrado) foi...'
    }
];

const ButtonVerMais = ({ onClick }) => {
    return (
        <div className={styles['button-ver-mais']} onClick={() => onClick && onClick()}>
            <div className={styles['button-ver-mais-text']}>Ver mais</div>
            <div className={styles['button-ver-mais-icon']}>
                <Icon icon={IconChevronRight} customSize />
            </div>
            <Ink />
        </div>
    )
}

const Home = () => {
    return (
        <div className={styles['container']}>

            <section className={styles['slider']}>
                <Slider
                    speed={500}
                    fade
                    autoplay
                    autoplaySpeed={4000}
                >
                    {slides.map(({ id, text, subtext }, i) => {
                        const itemClass = `${styles['slider-item']} ${styles[id]}`;
                        return (
                            <div className={itemClass} key={i}>
                                <div className={'holder'}>
                                    <div className={styles['slider-item-holder']}>
                                        <div className={styles['slider-item-content']}>
                                            <div className={styles['slider-item-text']}>{text}</div>
                                            {subtext && <div className={styles['slider-item-subtext']}>{subtext}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </section>

            <Page
                headValues={{
                    title: 'Home',
                    canonical: `${Environment.BasePath()}${Pages.Home}`
                }}
            >
                <section className={styles['es']}>
                    <div className={styles['es-image']}></div>
                    <div className={styles['es-main']}>
                        <div className={styles['es-title']}>
                            <h1>O que é a engenharia de sistemas?</h1>
                        </div>
                        <div className={styles['es-text']}>
                            <span className={'text'}>
                                O seu foco encontra-se em definir, de maneira precoce no ciclo de desenvolvimento de um sistema, as necessidades do usuário, bem como as funcionalidades requeridas, realizando a documentação sistemática dos requisitos, e abordando a síntese de projeto e a etapa de validação de forma a considerar o problema completo: operação; custos e cronogramas; performance; treinamento e suporte; teste; instalação; fabricação.
                                {' '}<a href={Pages.Sobre.EngenhariaDeSistemas}>Veja mais</a>
                            </span>
                        </div>
                    </div>
                </section>

                <section className={styles['numbers']}>
                    {numbers.map(({ prefix, number, text, suffix }, i) => {
                        return (
                            <div className={styles['numbers-item']} key={i}>
                                <div className={styles['numbers-item-prefix']}>{prefix}</div>
                                <div className={styles['numbers-item-number']}>{number}</div>
                                <div className={styles['numbers-item-text']}>{text}</div>
                                <div className={styles['numbers-item-suffix']}>{suffix}</div>
                            </div>
                        )
                    })}
                </section>

                <section className={styles['nucleos']}>
                    <div className={styles['nucleos-title']}>
                        <div className={styles['nucleos-title-text']}><h1>Núcleos conceituais</h1></div>
                        <div className={styles['nucleos-title-button']}><ButtonVerMais onClick={() => Util.redirect(Pages.Curso.NucleosConceituais)} /></div>
                    </div>
                    <div className={styles['nucleos-list']}>
                        <div className={styles['nucleos-list-track']}>
                            {nucleos.map(({ id, title, text }, i) => {
                                return (
                                    <div className={`${styles['nucleos-list-item']} ${styles[id]}`} key={i}>
                                        <div className={styles['nucleos-list-item-image']}></div>
                                        <div className={styles['nucleos-list-item-title']}>{title}</div>
                                        <div className={styles['nucleos-list-item-text']}><span className={'text'}>{text}</span></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles['nucleos-button']}>
                        <ButtonVerMais onClick={() => Util.redirect(Pages.Curso.NucleosConceituais)} />
                    </div>
                </section>

                <section className={styles['grade']}>
                    <div className={styles['grade-title']}><h1>Conheça a grade curricular do curso</h1></div>
                    <div className={styles['grade-text']}>
                        <span className={'text'}>Explore as matérias, as suas ementas e as áreas de conhecimento que compõem a graduação em Engenharia de Sistemas.</span>
                    </div>
                    <div className={styles['grade-button']}>
                        <Button
                            value={'Veja mais'}
                            onClick={() => Util.redirect(Pages.Curso.GradeCurricular)}
                        />
                    </div>
                </section>

                <div className={styles['internacionais']}>
                    <div className={styles['internacionais-title']}>
                        <div className={styles['internacionais-title-text']}><h1>Cursos internacionais</h1></div>
                        <div className={styles['internacionais-title-button']}><ButtonVerMais onClick={() => Util.redirect(Pages.Sobre.CursosInternacionais)} /></div>
                    </div>
                    <div className={styles['internacionais-list']}>
                        <div className={styles['internacionais-list-track']}>
                            {internacionais.map(({ id, title, location, text }, i) => {
                                return (
                                    <div className={`${styles['internacionais-list-item']} ${styles[id]}`} key={i}>
                                        <div className={styles['internacionais-list-item-image']}></div>
                                        <div className={styles['internacionais-list-item-title']}>{title}</div>
                                        <div className={styles['internacionais-list-item-location']}>{location}</div>
                                        <div className={styles['internacionais-list-item-text']}><span className={'text'}>{text}</span></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles['internacionais-button']}>
                        <ButtonVerMais onClick={() => Util.redirect(Pages.Sobre.CursosInternacionais)} />
                    </div>
                </div>
            </Page>

        </div>
    )
}

export default Home;

import Pages from './Pages';

const Constants = {
    PageTitleDefault: 'Engenharia de Sistemas - UFMG',

    Email: 'info@cartransport4u.com',
    EmailLink: 'mailto:info@cartransport4u.com',

    Facebook: 'https://facebook.com/engdesistemasufmg',
    Instagram: 'https://instagram.com/geesufmg',

    Menu: [
        {
            Label: 'Home',
            Target: Pages.Home
        },
        {
            Label: 'Sobre',
            Target: Pages.Sobre.Base,
            List: [
                { Label: 'Engenharia de sistemas', Target: Pages.Sobre.EngenhariaDeSistemas },
                { Label: 'Sobre o curso na UFMG', Target: Pages.Sobre.CursoNaUfmg },
                { Label: 'Surgimento', Target: Pages.Sobre.Surgimento },
                { Label: 'Bases legais', Target: Pages.Sobre.BasesLegais },
                { Label: 'Cursos internacionais', Target: Pages.Sobre.CursosInternacionais }
            ]
        },
        {
            Label: 'O Curso',
            Target: Pages.Curso.Base,
            List: [
                { Label: 'Núcleos conceituais', Target: Pages.Curso.NucleosConceituais },
                { Label: 'Formas de ingresso', Target: Pages.Curso.FormasIngresso },
                { Label: 'Grade curricular', Target: Pages.Curso.GradeCurricular }
            ]
        },
        {
            Label: 'Contato',
            Target: Pages.Contato.Base,
            List: [
                { Label: 'Fale conosco', Target: Pages.Contato.FaleConosco },
                { Label: 'Perguntas & respostas', Target: Pages.Contato.PerguntasRespostas }
            ]
        }
    ]
};

export default Constants;

import history from '../infrastructure/history';

const Util = {
    redirect: (path, options = { newTab: false, scrollToTop: true }) => {
        if(typeof path !== 'string') {
            console.error('Util.redirect: path must be a string');
            return;
        }
        
        if (path.includes('http') || path.includes('tel:') || path.includes('mailto:') || options.newTab) {
            const a = document.createElement('a');
            a.setAttribute('href', path);
            a.setAttribute('rel', 'noopener noreferrer');
            a.setAttribute('target', '_blank');
            a.click();
            return;
        }

        if(options.scrollToTop)
            Util.scrollTo(0, false);

        history.push(path);
    },

    emailValid: email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    applyMask: (mask, value) => {
        if(!mask)
            return value;
        
        if(!value?.length)
            return '';

        let _value = value.replace(/[^a-zA-Z0-9]/gi, '').split('');
        let _mask = mask.split('');
        for(let i = 0; ; i++) {
            if(i > mask.length)
                break;

            if(_mask[i] !== 'x') {
                continue;
            }
            else{
                _mask[i] = _value[0];
                _value.splice(0, 1);

                if(_value.length === 0) {
                    const increment = _mask[i + 1] !== 'x' && value.length === i + 2 ? i + 2 : i + 1;
                    _mask.splice(increment, _mask.length);
                    break;
                }
            }
        }
        return _mask.join('');
    },

    isMobile: () => {
        return window.innerWidth < 768;
    },

    isNumeric: (str, ignore, mask) => {
        if(str === null || str === undefined)
            return false;

        const _ignore = ignore || [];
        const _mask = mask || '';

        const _ignoreFormatted = _ignore.join();
        const _maskFormatted = [ ...new Set(_mask.replace(/x/gi, '')) ].join().replace('/', '\\/');
        
        const regex = new RegExp(`^[0-9${_ignoreFormatted}${_maskFormatted}]+`);
        return str.replace(regex, '').length === 0;
    },

    isAlpha: (str, ignore = []) => {
        let _ignore = [' ', 'á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ç', 'Ç', 'ã', 'õ', 'Ã', 'Õ', '´', '˜'];
        _ignore = [ ...ignore, ..._ignore ];
        _ignore = _ignore.join('').replace(/[.*+?^${}()\-|[\]\\]/g, '\\$&');
        const regex = new RegExp(`[^a-zA-Z${_ignore}]`, 'gi');
        const _str = str.replace(regex, '');
        return str.length === _str.length;
    },

    scrollTo: (height, smooth = true) => {
        window.scroll({ top: height, behavior: smooth ? 'smooth' : 'auto' });
    },

    scrolled: () => {
        const x = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
        const y = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        return { x, y };
    },

    appendScriptTagToHead: src => {
        if(!src)
            return;
        
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        document.head.appendChild(script);
    },

    appendScriptContentToHead: content => {
        if(!content)
            return;
        
        const script = document.createElement('script');
        script.innerHTML = content;
        document.head.appendChild(script);
    },

    analyticsEvent({ category, action, label }) {
        window.dispatchEvent(new CustomEvent('analytics.event', {
            detail: { category, action, label }
        }));
    },

    getPathnameParams: () => {
        const { pathname } = history.location;
        
        return pathname.slice(1).split('/');
    },

    getElementPosition: elementRef => {
        let element = elementRef.current ?? elementRef;

        let x = 0, y = 0;

        while(element) {
            x += element.offsetLeft - element.scrollLeft + element.clientLeft;
            y += element.offsetTop - element.scrollTop + element.clientTop;
            element = element.offsetParent;
        }

        return { x, y };
    },

    getQueryStringParam: (...args) => {
        const url = window.location.href;
        const results = {};

        for(const i in args) {
            const name = args[i].replace(/[\]]/g, '\\$&');
            const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
            const result = regex.exec(url);

            let param = null;
            if(result && result[2])
                param = decodeURIComponent(result[2].replace(/\+/g, ' '));
            results[name] = param;
        }
        
        return results;
    }
}

export default Util;

import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import AppLoading from '../components/AppLoading';

import Util from '../common/Util';
import Pages from '../common/Pages';

import styles from './App.module.scss';

import Site from '../features/site';
const Admin = React.lazy(() => import('../features/admin'));

class App extends Component {
    componentDidMount() {
        const { setMobile } = this.props;

        setMobile(Util.isMobile());
        window.addEventListener('resize', () => setMobile(Util.isMobile()));
    }

    render() {
        const { history } = this.props;

        return (
            <Router history={history}>
                <div className={styles['container']}>

                    <div className={styles['content']}>
                        <Suspense fallback={<AppLoading />}>
                            <Switch>
                                <Route path={Pages.Admin.Base} render={() => <Admin history={history} />} />
                                <Route render={() => <Site history={history} />} />
                            </Switch>
                        </Suspense>
                    </div>

                </div>
            </Router>
        )
    }
}

export default App;
